var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: !_vm.live ? _vm.askClasses() : "pb-5 pb-md-8",
      attrs: { id: "ask" },
    },
    [
      _c(
        "b-container",
        { class: !_vm.live ? "px-0" : null, attrs: { fluid: !_vm.live } },
        [
          _c("h4", [
            _vm._v(
              _vm._s(
                _vm.asset.asset_qa.headline
                  ? _vm.asset.asset_qa.headline
                  : _vm.$t("common.ask-question")
              )
            ),
          ]),
          _vm.asset.asset_qa.description
            ? _c("p", [_vm._v(_vm._s(_vm.asset.asset_qa.description))])
            : _vm._e(),
          _c(
            "b-form",
            {
              staticClass: "pt-5",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.sendQuestion.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.asset.asset_qa.name
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionName",
                                  type: "text",
                                  name: "name",
                                  placeholder:
                                    _vm.$t("form.name") +
                                    (_vm.asset.asset_qa.name === 2 ? " *" : ""),
                                  disabled: _vm.loading,
                                  required:
                                    _vm.asset.asset_qa.name === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "name", $$v)
                                  },
                                  expression: "questionForm.name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.asset_qa.title
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionTitle",
                                  type: "text",
                                  name: "title",
                                  placeholder:
                                    _vm.$t("form.title") +
                                    (_vm.asset.asset_qa.title === 2
                                      ? " *"
                                      : ""),
                                  disabled: _vm.loading,
                                  required:
                                    _vm.asset.asset_qa.title === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "title", $$v)
                                  },
                                  expression: "questionForm.title",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.asset_qa.phone
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionPhone",
                                  type: "tel",
                                  name: "phone",
                                  placeholder:
                                    _vm.$t("form.phone") +
                                    (_vm.asset.asset_qa.phone === 2
                                      ? " *"
                                      : ""),
                                  disabled: _vm.loading,
                                  required:
                                    _vm.asset.asset_qa.phone === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "phone", $$v)
                                  },
                                  expression: "questionForm.phone",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.asset_qa.company
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionCompany",
                                  type: "text",
                                  name: "company",
                                  placeholder:
                                    _vm.$t("form.company") +
                                    (_vm.asset.asset_qa.company === 2
                                      ? " *"
                                      : ""),
                                  disabled: _vm.loading,
                                  required:
                                    _vm.asset.asset_qa.company === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.company,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "company", $$v)
                                  },
                                  expression: "questionForm.company",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.asset_qa.email
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionEmail",
                                  type: "email",
                                  name: "email",
                                  placeholder:
                                    _vm.$t("form.email") +
                                    (_vm.asset.asset_qa.email === 2
                                      ? " *"
                                      : ""),
                                  disabled: _vm.loading,
                                  required:
                                    _vm.asset.asset_qa.email === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "email", $$v)
                                  },
                                  expression: "questionForm.email",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "textarea" },
                        [
                          _c("b-form-textarea", {
                            class: _vm.inputClasses(),
                            attrs: {
                              id: "questionText",
                              placeholder: _vm.$t("form.question") + " *",
                              rows: "3",
                              disabled: _vm.loading,
                              required: "",
                            },
                            model: {
                              value: _vm.questionForm.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.questionForm, "text", $$v)
                              },
                              expression: "questionForm.text",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _vm.asset.asset_qa.gdpr
                    ? _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  class: _vm.checkClasses(),
                                  attrs: {
                                    id: "questionGdpr",
                                    name: "questionGdpr",
                                    disabled: _vm.loading,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.questionForm.gdpr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.questionForm, "gdpr", $$v)
                                    },
                                    expression: "questionForm.gdpr",
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "small",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.asset.asset_qa.gdpr + " *"
                                      ),
                                    },
                                  }),
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "check", size: "sm" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-md-right ml-auto pt-1",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("p", { staticClass: "small" }, [
                        _vm._v("* " + _vm._s(_vm.$t("form.mandatory"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm.success || _vm.error
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "pb-3", attrs: { cols: "12" } },
                        [
                          _vm.success
                            ? _c(
                                "b-alert",
                                {
                                  attrs: {
                                    variant: "success",
                                    show: 5,
                                    fade: "",
                                  },
                                },
                                [
                                  _c("p", { staticClass: "m-0 small" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("form.success")) + "."
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.error
                            ? _c(
                                "b-alert",
                                {
                                  attrs: {
                                    variant: "danger",
                                    show: 5,
                                    fade: "",
                                  },
                                },
                                [
                                  _c("p", { staticClass: "m-0 small" }, [
                                    _vm._v(_vm._s(_vm.$t("form.error")) + "."),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _vm.loading
                    ? _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: ["fas", "spinner"],
                              spin: true,
                              size: "lg",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "b-col",
                        {
                          class: !_vm.live ? "text-right" : null,
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "b-btn",
                            {
                              class: _vm.asset.theme.skin_button_text_color
                                ? "text-" +
                                  _vm.asset.theme.skin_button_text_color
                                : null,
                              attrs: {
                                type: "submit",
                                variant: _vm.asset.theme.skin_button_color
                                  ? _vm.asset.theme.skin_button_color
                                  : "primary",
                                disabled: _vm.loading,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("form.send")))]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }