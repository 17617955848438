var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "d-flex h-100 pt-10", attrs: { id: "category" } },
    [
      _vm.category && _vm.assets
        ? _c(
            "b-container",
            { staticClass: "pt-10" },
            [
              _c(
                "b-row",
                { staticClass: "pb-3" },
                [
                  _c("b-col", { staticClass: "pt-10" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.category.title))]),
                  ]),
                ],
                1
              ),
              _c("div", [_c("Assets")], 1),
            ],
            1
          )
        : _c("b-container", [_c("Loading")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }