<template>
	<section id="category" class="d-flex h-100 pt-10">
		<b-container v-if="category && assets" class="pt-10" >
			<b-row class="pb-3">
				<b-col class="pt-10">
					<h3>{{ category.title }}</h3>
				</b-col>
			</b-row>
			<div >
				<Assets />
			</div>
		</b-container>
		<b-container v-else>
			<Loading />
		</b-container>
	</section>
</template>
<script>
	import Assets from '@/components/Assets.vue'
	import Loading from '@/components/Loading.vue'
    export default {
		name: 'Category',
		components: {
			Assets,
			Loading
		},
		computed: {
			category() {
				return this.$store.getters.category
			},
			assets() {
				return this.$store.getters.assets
			}
		},
		methods: {
		},
		mounted() {
		}
    }
</script>
