var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "prelive" } },
    [
      _c(
        "b-container",
        { staticClass: "py-md-5" },
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mw-lg-50", attrs: { cols: "12", lg: "auto" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "p-5 p-md-8 rounded",
                      class: [
                        _vm.asset.background_color
                          ? "bg-" + _vm.asset.background_color
                          : "bg-light",
                        _vm.asset.text_color
                          ? "text-" + _vm.asset.text_color
                          : "text-dark",
                      ],
                    },
                    [
                      _c("h4", {
                        domProps: {
                          innerHTML: _vm._s(_vm.makeBr(_vm.asset.title)),
                        },
                      }),
                      _vm.asset.is_countdown && _vm.starting
                        ? _c("p", { staticClass: "lead m-0 pt-3" }, [
                            _vm._v(_vm._s(_vm.$t("countdown.event")) + "..."),
                          ])
                        : _vm.asset.prelive
                        ? _c("p", {
                            staticClass: "lead m-0 pt-3",
                            domProps: {
                              innerHTML: _vm._s(_vm.makeBr(_vm.asset.prelive)),
                            },
                          })
                        : _vm.asset.description
                        ? _c("p", {
                            staticClass: "lead m-0 pt-3",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.makeBr(_vm.asset.description)
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm.asset.is_countdown
                        ? _c("countdown", {
                            staticClass: "pt-5 pt-lg-7",
                            on: { cdStarting: _vm.cdStarts },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm.preliveQa()
                ? _c(
                    "b-col",
                    {
                      staticClass: "ml-auto mw-lg-50",
                      attrs: { cols: "12", lg: "auto" },
                    },
                    [_c("Ask")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }