<template>
	<div class="assets" v-if="$route.name == 'category' && assets && assets.length > 0 || assets && assets.length > 1">
		<b-row class="pt-4">
			<b-col>
				<!-- <h4>Assets</h4> -->
			</b-col>
		</b-row>
		<b-row class="pb-4">
			<b-col v-for="asset in filtered" :key="asset.id" @click="goToAsset(asset)" class="col-6 col-lg-4 pb-3">
				<div class="item">
					<div class="img" :class="asset.thumbnail_url ? 'no-icon' : ''">
						<img :src="asset.thumbnail_url"/>
					</div>
					<p class="big mb-0 pt-3" v-html="asset.title"></p>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	export default {
		computed: {
			filtered() {
				return this.$route.name == 'category' 
					? this.assets
					: this.assets.filter(a => a.id != this.asset.id )                
			},
			assets() {
				return this.$store.getters.assets
			},
			asset() {
				return this.$store.getters.asset
			},
			password() {
				return this.$store.getters.password
			},
			type() {
				return this.$store.getters.type
			}
		},
		methods: {
			goToAsset(asset) {
				const url = new URL('/'+asset.asset_category_key, window.location.origin)
				window.location.href = url.toString()
				// // this.$store.dispatch('loaded', false)
				// this.$store.dispatch('asset', { slug: this.type.uuid ? asset.asset_category_key : asset.slug, password: this.password })
				// .then(() => {
				// // 	this.$store.dispatch('loaded', true)
				// 	this.$router.push({ name: 'asset', params: { slug:  asset.asset_category_key } }).then(() => { this.$router.go() })
				// })
			}
		}
	}
</script>
