<template>
	<div id="prelive">
		<b-container class="py-md-5">
			<b-row align-v="center">
				<b-col 	cols="12"
						lg="auto"
						class="mw-lg-50">
					<div class="p-5 p-md-8 rounded" :class="[asset.background_color ? 'bg-'+asset.background_color : 'bg-light', asset.text_color ? 'text-'+asset.text_color : 'text-dark']">
						<h4 v-html="makeBr(asset.title)"></h4>
						<p class="lead m-0 pt-3" v-if="asset.is_countdown && starting">{{ $t('countdown.event') }}...</p>
						<p class="lead m-0 pt-3" v-html="makeBr(asset.prelive)" v-else-if="asset.prelive"></p>
						<p class="lead m-0 pt-3" v-html="makeBr(asset.description)" v-else-if="asset.description"></p>
						<countdown @cdStarting="cdStarts" class="pt-5 pt-lg-7" v-if="asset.is_countdown"></countdown>
					</div>
				</b-col>
				<b-col 	cols="12"
						lg="auto"
						class="ml-auto mw-lg-50"
						v-if="preliveQa()">
					<Ask />
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	import Countdown from '@/components/asset/Countdown.vue'
	import Ask from '@/components/asset/Ask.vue'
	export default {
		data() {
			return {
				starting: false
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		components: {
			Countdown,
			Ask
		},
		methods: {
			preliveQa() {
				if(this.asset.asset_qa && this.asset.asset_qa.enabled && this.asset.asset_qa.prelive) return true
				return false
			},
			makeBr(text) {
				return text.replace('\n', '<br>')
			},
			cdStarts(val) {
				this.starting = val
			}
		}
	}
</script>
