<template>
	<section 	id="asset"
				class="d-flex"
				v-if="asset"
				:class="getClasses()">
		<PreLive v-if="[0].includes(asset.broadcasting)" />
		<Live v-else-if="[1].includes(asset.broadcasting)" />
		<PostLive v-else-if="[2,3].includes(asset.broadcasting) && (asset.type === 5 || ![3,5].includes(asset.status_id))" />
		<OnDemand v-else />
	</section>
</template>
<script>
	import PreLive from '@/components/asset/PreLive.vue'
	import Live from '@/components/asset/Live.vue'
	import PostLive from '@/components/asset/PostLive.vue'
	import OnDemand from '@/components/asset/OnDemand.vue'
	export default {
		name: 'Asset',
		components: {
			PreLive,
			Live,
			PostLive,
			OnDemand
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			channel() {
				return this.$store.getters.channel
			}
		},
		methods: {
			isPrePostLive() {
				if(this.$route.name === 'preview') return false
				return ([0, 2, 3].includes(this.asset.broadcasting) && (this.asset.type === 5 || ![3,5].includes(this.asset.status_id))) ? true : false
			},
			getClasses() {
				return [
					this.isPrePostLive() ? 'justify-content-center' : null
				]
			}
		}
	}
</script>
