<template>
	<div id="ask" :class="!live ? askClasses() : 'pb-5 pb-md-8'">
		<b-container :fluid="!live" :class="!live ? 'px-0' : null">
			<h4>{{asset.asset_qa.headline ? asset.asset_qa.headline :  $t('common.ask-question') }}</h4>
			<p v-if="asset.asset_qa.description">{{ asset.asset_qa.description }}</p>
			<b-form class="pt-5" @submit.prevent="sendQuestion">
				<b-row>
					<b-col cols="12">
						<b-form-group v-if="asset.asset_qa.name">
							<b-form-input 	id="questionName"
											v-model="questionForm.name"
											type="text"
											name="name"
											:class="inputClasses()"
											:placeholder="$t('form.name') + (asset.asset_qa.name === 2 ? ' *' : '')"
											:disabled="loading"
											:required="asset.asset_qa.name === 2 ? true : false">
							</b-form-input>
						</b-form-group>
						<b-form-group v-if="asset.asset_qa.title">
							<b-form-input 	id="questionTitle"
											v-model="questionForm.title"
											type="text"
											name="title"
											:class="inputClasses()"
											:placeholder="$t('form.title') + (asset.asset_qa.title === 2 ? ' *' : '')"
											:disabled="loading"
											:required="asset.asset_qa.title === 2 ? true : false">
							</b-form-input>
						</b-form-group>
						<b-form-group v-if="asset.asset_qa.phone">
							<b-form-input 	id="questionPhone"
											v-model="questionForm.phone"
											type="tel"
											name="phone"
											:class="inputClasses()"
											:placeholder="$t('form.phone') + (asset.asset_qa.phone === 2 ? ' *' : '')"
											:disabled="loading"
											:required="asset.asset_qa.phone === 2 ? true : false">
							</b-form-input>
						</b-form-group>
						<b-form-group v-if="asset.asset_qa.company">
							<b-form-input 	id="questionCompany"
											v-model="questionForm.company"
											type="text"
											name="company"
											:class="inputClasses()"
											:placeholder="$t('form.company') + (asset.asset_qa.company === 2 ? ' *' : '')"
											:disabled="loading"
											:required="asset.asset_qa.company === 2 ? true : false">
							</b-form-input>
						</b-form-group>
						<b-form-group v-if="asset.asset_qa.email">
							<b-form-input 	id="questionEmail"
											v-model="questionForm.email"
											type="email"
											name="email"
											:class="inputClasses()"
											:placeholder="$t('form.email') + (asset.asset_qa.email === 2 ? ' *' : '')"
											:disabled="loading"
											:required="asset.asset_qa.email === 2 ? true : false">
							</b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12">
						<b-form-group class="textarea">
							<b-form-textarea 	id="questionText"
												v-model="questionForm.text"
												:class="inputClasses()"
												:placeholder="$t('form.question') + ' *'"
												rows="3"
												:disabled="loading"
												required />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="auto" v-if="asset.asset_qa.gdpr">
						<b-form-group>
							<b-form-checkbox 	id="questionGdpr"
												:class="checkClasses()"
												v-model="questionForm.gdpr"
												name="questionGdpr"
												:disabled="loading"
												required>
								<span class="small" v-html="asset.asset_qa.gdpr + ' *'" />
								<font-awesome-icon icon="check" size="sm"></font-awesome-icon>
							</b-form-checkbox>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6" class="text-md-right ml-auto pt-1">
						<p class="small">* {{ $t('form.mandatory') }}</p>
					</b-col>
				</b-row>
				<b-row v-if="success || error">
					<b-col cols="12" class="pb-3">
						<b-alert variant="success" :show="5" fade v-if="success">
							<p class="m-0 small">{{ $t('form.success') }}.</p>
						</b-alert>
						<b-alert variant="danger" :show="5" fade v-if="error">
							<p class="m-0 small">{{ $t('form.error') }}.</p>
						</b-alert>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" class="text-center" v-if="loading">
						<font-awesome-icon :icon="['fas', 'spinner']" :spin="true" size="lg" />
					</b-col>
					<b-col cols="12" :class="!live ? 'text-right' : null" v-else>
						<b-btn type="submit" :variant="asset.theme.skin_button_color ? asset.theme.skin_button_color : 'primary'" :class="asset.theme.skin_button_text_color ? 'text-' + asset.theme.skin_button_text_color : null" :disabled="loading">{{ $t('form.send') }}</b-btn>
					</b-col>
				</b-row>
			</b-form>
		</b-container>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				questionForm: {},
				success: false,
				error: false,
				loading: false,
				inputCounter: 0,
				reqFieldsCount: 0
			}
		},
		props: {
			live: {
				default: false
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			attendee() {
				return this.$store.getters.attendee
			}
		},
		methods: {
			getQuestionForm() {
				return this.attendee
				? {
					name: this.attendee.name ? this.attendee.name : null,
					company: this.attendee.company ? this.attendee.company : null,
					email: this.attendee.email ? this.attendee.email : null,
					phone: this.attendee.phone ? this.attendee.phone : null,
					title: this.attendee.title ? this.attendee.title : null
				}
				: {}
			},
			sendQuestion() {
				this.error = false
				this.success = false
				this.loading = true
				this.questionForm.token = this.$route.query.token
				this.questionForm.password = this.$route.query.password
				this.$store.dispatch('postQuestion', { slug: this.asset.asset_category_key, data: this.questionForm })
				.then(() => {
					this.loading = false
					this.success = true
					setTimeout(() => { this.success = false }, 5000)
					this.questionForm.text = null
				})
				.catch(() => {
					this.loading = false
					this.error = true
					setTimeout(() => { this.error = false }, 5000)
				})
			},
			countFields() {
				let fields = this.asset.asset_qa
				for(const field in fields) {
					if(fields[field] && _.includes(['company', 'email', 'name', 'phone', 'title'], field)) this.inputCounter++
					if(fields[field] === 2) this.reqFieldsCount++
				}
			},
			inputClasses() {
				return [
					this.asset.theme.skin_input_background_color ? 'bg-'+this.asset.theme.skin_input_background_color : 'bg-white',
					this.asset.theme.skin_input_border_color ? 'border-'+this.asset.theme.skin_input_border_color : 'border-light',
					this.asset.theme.skin_input_text_color ? 'text-'+this.asset.theme.skin_input_text_color : 'text-dark'
				]
			},
			checkClasses() {
				return [
					this.asset.theme.skin_input_background_color ? 'bg-'+this.asset.theme.skin_input_background_color : 'bg-white',
					this.asset.theme.skin_input_border_color ? 'border-'+this.asset.theme.skin_input_border_color : 'border-light',
					this.asset.theme.skin_button_color ? 'checked-bg-'+this.asset.theme.skin_button_color : 'checked-bg-dark',
					this.asset.theme.skin_button_text_color ? 'custom-text-'+this.asset.theme.skin_button_text_color : 'custom-text-light'
				]
			},
			askClasses() {
				return [
					this.asset.background_color ? 'bg-'+this.asset.background_color : 'bg-light',
					this.asset.text_color ? 'text-'+this.asset.text_color : 'text-dark',
					'mt-5 p-5 p-lg-7 rounded'
				]
			}
		},
		mounted() {
			this.countFields()
			this.questionForm = this.getQuestionForm()
		}
	}
</script>
