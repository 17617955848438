var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ondemand" } },
    [
      _c(
        "div",
        { class: _vm.getMediaClasses(), attrs: { id: "media" } },
        [
          _c("b-container", [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("Player"),
                _vm.asset.slideinstances.length ? _c("BigSlide") : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.asset.slideinstances.length ? _c("Controllers") : _vm._e(),
      _vm.asset.slideinstances.length ? _c("Slides") : _vm._e(),
      _c(
        "div",
        { staticClass: "py-5 py-md-8", attrs: { id: "info" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pb-7 pb-md-0",
                      attrs: {
                        cols: "12",
                        md:
                          (_vm.asset.chapters && _vm.asset.chapters.length) ||
                          _vm.asset.downloadable === 1 ||
                          _vm.filteredFormats().length ||
                          _vm.filteredAssets().length
                            ? "8"
                            : "12",
                      },
                    },
                    [
                      _c("h2", {
                        domProps: {
                          innerHTML: _vm._s(_vm.makeBr(_vm.asset.title)),
                        },
                      }),
                      _vm.asset.description
                        ? _c("p", {
                            staticClass: "lead m-0 pt-3",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.makeBr(_vm.asset.description)
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm.asset.tags && _vm.asset.tags.length
                        ? _c(
                            "div",
                            { staticClass: "pt-3", attrs: { id: "tags" } },
                            _vm._l(_vm.asset.tags, function (tag, key) {
                              return _c(
                                "b-badge",
                                { key: key, staticClass: "mr-1" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(tag.name ? tag.name : tag) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  (_vm.asset.chapters && _vm.asset.chapters.length) ||
                  _vm.asset.downloadable === 1 ||
                  _vm.filteredFormats().length ||
                  _vm.filteredAssets().length
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _vm.asset.downloadable === 1 ||
                          _vm.filteredFormats().length ||
                          _vm.filteredAssets().length
                            ? _c("Downloads")
                            : _vm._e(),
                          _vm.asset.chapters && _vm.asset.chapters.length
                            ? _c("Chapters")
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.asset.slideinstances.length && _vm.viewSlide
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "slideModal",
                size: "xl",
                show: "",
                "hide-header": "",
                "hide-footer": "",
                centered: "",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.zoomOutSlide.apply(null, arguments)
                },
              },
            },
            [
              _c("b-img", {
                attrs: { src: _vm.slideUrl(_vm.viewSlide), fluid: "" },
                on: { click: _vm.zoomOutSlide },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }