var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.$route.name == "category" &&
    _vm.assets &&
    _vm.assets.length > 0) ||
    (_vm.assets && _vm.assets.length > 1)
    ? _c(
        "div",
        { staticClass: "assets" },
        [
          _c("b-row", { staticClass: "pt-4" }, [_c("b-col")], 1),
          _c(
            "b-row",
            { staticClass: "pb-4" },
            _vm._l(_vm.filtered, function (asset) {
              return _c(
                "b-col",
                {
                  key: asset.id,
                  staticClass: "col-6 col-lg-4 pb-3",
                  on: {
                    click: function ($event) {
                      return _vm.goToAsset(asset)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        class: asset.thumbnail_url ? "no-icon" : "",
                      },
                      [_c("img", { attrs: { src: asset.thumbnail_url } })]
                    ),
                    _c("p", {
                      staticClass: "big mb-0 pt-3",
                      domProps: { innerHTML: _vm._s(asset.title) },
                    }),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }