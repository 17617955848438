<template>
	<section 	id="preview"
				class="d-flex">
		<Live />
	</section>
</template>
<script>
	import Live from '@/components/asset/Live.vue'
	export default {
		name: 'Preview',
		components: {
			Live
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			channel() {
				return this.$store.getters.channel
			}
		}
	}
</script>
