<template>
	<div id="countdown" v-if="counterObj">
		<b-row>
			<b-col cols="3" lg="auto" class="text-center flex-lg-grow-1 px-0 px-lg-5">
				<h2 class="m-0 font-weight-bold">{{ counterObj.days }}</h2>
				<p class="m-0 smaller text-uppercase">{{$t('countdown.days')}}</p>
			</b-col>
			<b-col cols="3" lg="auto" class="text-center flex-lg-grow-1 px-0 px-lg-5">
				<h2 class="m-0 font-weight-bold">{{ counterObj.hours }}</h2>
				<p class="m-0 smaller text-uppercase">{{$t('countdown.hours')}}</p>
			</b-col>
			<b-col cols="3" lg="auto" class="text-center flex-lg-grow-1 px-0 px-lg-5">
				<h2 class="m-0 font-weight-bold">{{ counterObj.minutes }}</h2>
				<p class="m-0 smaller text-uppercase">{{$t('countdown.minutes')}}</p>
			</b-col>
			<b-col cols="3" lg="auto"  class="text-center flex-lg-grow-1 px-0 px-lg-5">
				<h2 class="m-0 font-weight-bold">{{ counterObj.seconds }}</h2>
				<p class="m-0 smaller text-uppercase">{{$t('countdown.seconds')}}</p>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				counterObj: null
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		methods: {
			initCountdown(cDate) {
				const now = this.$moment()
				const end = this.$moment.utc(cDate)
				const diff = end - now
				const dur = this.$moment.duration(end.diff(now)).asSeconds()
				const days = Math.floor(dur / (3600*24))
				const hours = Math.floor(dur % (3600*24) / 3600)
				const minutes = Math.floor(dur % 3600 / 60)
				const seconds = Math.floor(dur % 60)
				if(diff > 0) {
					this.counterObj = {
						days: days,
						hours: hours,
						minutes: minutes,
						seconds: seconds
					}
					setTimeout(function() {
						this.initCountdown(cDate)
					}.bind(this), 1000)
				} else {
					this.counterObj = null
					this.$emit('cdStarting', true)
				}
			}
		},
		mounted() {
			if (this.asset && this.asset.broadcasting_estimated_start) {
				this.initCountdown(this.asset.broadcasting_estimated_start)
			}
		},
		watch: {
			asset(data) {
				if(data.broadcasting_estimated_start) {
					this.initCountdown(data.broadcasting_estimated_start)
				}
			}
		}
	}
</script>
